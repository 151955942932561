import { graphql } from 'gatsby';
import React from 'react';
import tw from 'tailwind.macro';
import CollapsibleBox from '../components/collapsibleBox';
import Layout from '../components/layout';
import LeadText from '../components/leadText';
import Masthead from '../components/masthead';
import SafeLink from '../components/safeLink';
import SEO from '../components/seo';
import Button from '../components/button';

const Section = tw.section``;

const DataResearch = ({ data, location }) => {
  const parentTitle = '';
  const pageTitle = 'Data & Research';

  return (
    <Layout location={location} parentTitle={parentTitle} pageTitle={pageTitle}>
      <SEO title={pageTitle} />
      <Masthead
        parentTitle={parentTitle}
        pageTitle={pageTitle}
        backgroundImage={data.homeHero.childImageSharp.fluid}
        styling={[tw`bg-maroon`, `min-height:20vh;`]}
      />
      <Section css={tw`py-20 bg-light-gray-100 shadow-inner`}>
        <div className="container">
          <LeadText css={tw`mb-6`}>
            In the sections below, we provide links to useful research in social
            research and related fields.
          </LeadText>

          <CollapsibleBox collapsibleByDefault>
            <h2>LexisNexis COVID-19 Resource Centre</h2>
            <div>
              <LeadText>
                The Centre for Social Justice is partnering with
                LexisNexis.
              </LeadText>
              <p>
                COVID-19 represents a common enemy that we are all fighting
                across the globe. It continues to test all aspects of our
                societies - our people, our infrastructure, our systems and
                processes - and indeed, even our understanding and
                implementation of the Rule of Law.
              </p>
              <p>
                Legal technology company, LexisNexis South Africa, has drawn
                upon its global expertise to launch a COVID-19 Free Resource
                Centre aimed at informing the public of its rights and
                responsibilities during this period, as well as the rights and
                obligations of employers, employees and businesses.
              </p>
              <p>
                The COVID-19 portal provides free access to a convenient online
                platform housing resources and information that cover a variety
                of general public themes such as Labour Law, Immigration,
                Property and Planning, as well as Notices, Court Directives and
                Regulations pertaining to COVID-19.
              </p>
              <p>
                The site also includes commentary from legal experts around the
                country on emerging themes and topics around COVID-19 matters.
                In addition, LexisNexis’s editorial team has made available
                COVID- 19 legislation and regulation changes on the same site at
                no cost to the public. This provides direct access to a
                consolidated and authoritative source of COVID-19 regulatory
                information, together with useful tips, data and other helpful
                resources.
              </p>
              <Button
                to="https://www.lexisnexis.co.za/news-and-insights/covid-19-resource-centre?utm_source=social-justice-hub&utm_medium=referral&utm_campaign=covid-19"
                size="md"
              >
                Visit the LexisNexis resource centre &rarr;
              </Button>
            </div>
          </CollapsibleBox>

          <CollapsibleBox collapsibleByDefault>
            <h2>
              Law, Justice and Governance/Access to Justice and the Rule of Law
            </h2>
            <div>
              <ol>
                <li>
                  Adelman S & Paliwala (eds) A The Limits of Law and
                  Development: Neoliberalism, Governance and Social Justice
                  (2020), United Kingdom: Routledge.
                  <br />
                  <SafeLink
                    to="</SafeLink>https://doi.org/10.4324/9780203733561 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Smith DM “Social Justice and the Ethics of Development in
                  Post‐apartheid South Africa” (1999) 2 Ethics, Place &
                  Environment: A Journal of Philosophy & Geography 157–177.{' '}
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/1366879X.1999.11644244"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>
              Social Justice Planning, Monitoring, Measuring and Funding Tools
            </h2>
            <div>
              <ol>
                <li>
                  Aguilar SJ “Learning Analytics: at the Nexus of Big Data,
                  Digital Innovation, and Social Justice in Education” (2018) 62
                  TechTrends 37–45.
                  <br />
                  <SafeLink
                    to="https://link.springer.com/article/10.1007/s11528-017-0226-9"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Charmaz K “Grounded Theory in the 21st Century: Applications
                  for Advancing Social Justice Studies” in NK Denzin & YS
                  Lincoln (eds) The SAGE Handbook of Qualitative Research 3 ed
                  (2005) 507–535, Thousand Oaks, CA: SAGE Publications, Inc.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books?id=X85J8ipMpZEC&lpg=PA507&dq=Grounded%20Theory%20in%20the%2021st%20Century%3A%20Applications%20for%20advancing%20social%20justice%20studies&pg=PA507#v=onepage&q=Grounded%20Theory%20in%20the%2021st%20Century:%20Applications%20for%20advancing%20social%20justice%20studies&f=false"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Chapter available here &rarr;
                  </SafeLink>
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books/about/The_SAGE_Handbook_of_Qualitative_Researc.html?id=X85J8ipMpZEC&redir_esc=y"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Book available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Cokley K & GH Awad “In Defense of Quantitative Methods: Using
                  the ‘Master’s Tools’ to Promote Social Justice” (2013) 5
                  Journal for Social Action in Counseling and Psychology 26–41.
                  <br />
                  <SafeLink
                    to="https://openjournals.bsu.edu/jsacp/article/view/487"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Denzin NK & YS Lincoln (eds) Strategies of Qualitative Inquiry
                  4 ed (2012), Thousand Oaks, CA: SAGE Publications, Inc.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books/about/Strategies_of_Qualitative_Inquiry.html?id=NYIgAQAAQBAJ&redir_esc=y"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Fassinger R & SL Morrow “Toward Best Practices in
                  Quantitative, Qualitative, and Mixed-Method Research: A Social
                  Justice Perspective” (2013) 5 Journal for Social Action in
                  Counseling and Psychology 69–83.
                  <br />
                  <SafeLink
                    to="https://www.semanticscholar.org/paper/Toward-Best-Practices-in-Quantitative%2C-Qualitative%2C-Fassinger-Morrow/3133f34862794ea1e2e45dc21b67603aac368ada"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Johnson JA “From open data to information justice” (2014) 16
                  Ethics and Information Technology 263–274.
                  <br />
                  <SafeLink
                    to="https://www.springerprofessional.de/en/from-open-data-to-information-justice/5456588"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Lingard B, S Sellar & GC Savage “Re-articulating social
                  justice as equity in schooling policy: the effects of testing
                  and data infrastructures” (2014) 35 British Journal of
                  Sociology of Education 710–730.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/01425692.2014.919846"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Low S “Social Justice as a Framework for Evaluating Public
                  Space” in Mehta V & Palazzo D (eds) Companion to Public Space
                  (2020), London: Taylor & Francis Ltd.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books?hl=en&lr=&id=5urkDwAAQBAJ&oi=fnd&pg=PT85&ots=mc9-DVhsaS&sig=GBGZSBdu0RJarYT4qAGEK2YZVgE"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Maughan C & Anderson CR & Kneafsy, M “A Five-Point Framework
                  for Reading for Social Justice: A Case Study of Food Policy
                  Discourse in the Context of Brexit Britain” (2020) 9 Journal
                  of Agriculture, Food Systems, and Community Development
                  281–300.
                  <SafeLink
                    to="https://doi.org/10.5304/jafscd.2020.093.024"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Education, Research and Training</h2>
            <div>
              <ol>
                <li>
                  Bali M, Cronin C & Jhangiani SR “Framing Open Educational
                  Practices from a Social Justice Perspective” (2020) 1 Journal
                  of Interactive Media in Education 1–12.
                  <br />
                  <SafeLink
                    to="https://jime.open.ac.uk/articles/10.5334/jime.565/print/ "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Beycioğlu K “Leadership Effect in Social Justice” (2020)
                  Oxford Research Encyclopedia of Education.
                  <br />
                  <SafeLink
                    to="https://oxfordre.com/education/view/10.1093/acrefore/9780190264093.001.0001/acrefore-9780190264093-e-670 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Brand ST “Service-Learning and Social Justice for College and
                  University Students: Replacing Memorization with Meaning” in
                  Keengwe J (ed) Handbook of Research on Diversity and Social
                  Justice in Higher Education (2020) 78–104, United States: IGI
                  Global.
                  <br />
                  <SafeLink
                    to="https://www.igi-global.com/chapter/service-learning-and-social-justice-for-college-and-university-students/253793 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Burnes TR & Christensen NP “Still Wanting Change, Still
                  Working for Justice: An Introduction to the Special Issue on
                  Social Justice Training in Health Service Psychology” (2020)
                  14 Training and Education in Professional Psychology 87–91.
                  <br />
                  <SafeLink
                    to="http://dx.doi.org/10.1037/tep0000323"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Curtis-Boles H, Chupina AG & Okubo Y “Social justice
                  challenges: Students of color and critical incidents in the
                  graduate classroom” (2020) 14 Training and Education in
                  Professional Psychology 100–108.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1037/tep0000293"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Davidson E “Social justice or social control? An ethnographic
                  study of detached youth work in Scotland” (2020) 29 Scottish
                  Affairs 254–276.
                  <br />
                  <SafeLink
                    to="https://www.euppublishing.com/doi/abs/10.3366/scot.2020.0318"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  DeMatthews D “Making Sense of Social Justice Leadership: A
                  Case Study of a Principal’s Experiences to Create a More
                  Inclusive School” (2015) 14 Leadership and Policy in Schools
                  139–166.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/15700763.2014.997939"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Ertl MM, Agiliga AU, Martin CM, Taylor EJ, Kirkinis K,
                  Friedlander ML, Kimber JM, McNamara ML, Pazienza RL, Cabrera
                  Tineo YA & Eklund AC ““Hands-on” learning in a health service
                  psychology doctoral program through social justice
                  consultation.” (2020) Training and Education in Professional
                  Psychology.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1037/tep0000311"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Funk J & Guthadjaka K “Indigenous Authorship on Open and
                  Digital Platforms: Social Justice Processes and Potential”
                  (2020) 1 Journal of Interactive Media in Education 6.
                  <br />
                  <SafeLink
                    to="https://jime.open.ac.uk/articles/10.5334/jime.560/print/"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Ginwright S & T James “From assets to agents of change: Social
                  justice, organizing, and youth development” (2002) 96 New
                  Directions for Youth Development 27–46.
                  <br />
                  <SafeLink
                    to="https://onlinelibrary.wiley.com/doi/abs/10.1002/yd.25"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Hage SM & Miles JR “The social justice practicum in counseling
                  psychology training” (2020) 14 Training and Education in
                  Professional Psychology 156–166.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1037/tep0000299"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Hay J & C Beyers “An analysis of the South African model of
                  inclusive education with regard to social justice” (2011) 8
                  Africa Education Review 234–246.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/18146627.2011.603226"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Hill LD, J Baxen, AT Craig & H Namakula “Citizenship, Social
                  Justice, and Evolving Conceptions of Access to Education in
                  South Africa: Implications for Research” (2012) 36 Review of
                  Research in Education 239–260.
                  <br />
                  <SafeLink
                    to="https://journals.sagepub.com/doi/abs/10.3102/0091732X11421461?journalCode=rrea"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Jansen JD “Leading Against the Grain: The Politics and
                  Emotions of Leading for Social Justice in South Africa” (2006)
                  5 Leadership and Policy in Schools 37–51.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/15700760500484027"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Jenkins JJ, Sánchez LA, Schraedley MAK, Hannans J, Navick N &
                  Young J “Textbook Broke: Textbook Affordability as a Social
                  Justice Issue” (2020) 1 Journal of Interactive Media in
                  Education 3.
                  <br />
                  <SafeLink
                    to="https://www-jime.open.ac.uk/articles/10.5334/jime.549/"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Jimenz PP, Pascual J & Mejía A “Educating Engineers Under a
                  Social Justice Perspective” (2020) 10 International Journal of
                  Engineering Pedagogy 82–97.
                  <br />
                  <SafeLink
                    to="https://online-journals.org/index.php/i-jep/article/view/13673 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Knutson D, Chwalisz K, Becerra M, Christie MB, Coleman M, Esi
                  CC & Perinchery R “Student development in the context of a
                  beginning-level social justice practicum” (2020) 14 Training
                  and Education in Professional Psychology 145–155.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1037/tep0000288"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Lambert S & Czerniewicz L “Approaches to Open Education and
                  Social Justice Research” (2020) 1 Journal of Interactive Media
                  in Education 1.
                  <br />
                  <SafeLink
                    to="http://doi.org/10.5334/jime.584"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Mthethwa-Sommers S “Critical Service-Learning: Vehicle to
                  Social Justice Education” in Keengwe J (ed) Handbook of
                  Research on Diversity and Social Justice in Higher Education
                  (2020) 105–116, United States: IGI Global.
                  <br />
                  <SafeLink
                    to="https://www.igi-global.com/chapter/critical-service-learning/253794"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Nyatuka BO “Family-Community-Higher Education Partnership: A
                  Critical Pillar in Realizing Social Justice” in Keengwe J (ed)
                  Handbook of Research on Diversity and Social Justice in Higher
                  Education (2020) 129–148, United States: IGI Global.
                  <br />
                  <SafeLink
                    to="https://www.igi-global.com/chapter/family-community-higher-education-partnership/253796"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Peart T “Diversity and Social Justice: Promoting Academic
                  Achievement Among Diverse Learners in College” in Keengwe J
                  (ed) Handbook of Research on Diversity and Social Justice in
                  Higher Education (2020) 117–128, United States: IGI Global.
                  <br />
                  <SafeLink
                    to="https://www.igi-global.com/chapter/diversity-and-social-justice/253795"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Plen M Theorising Jewish social justice education: a
                  hermeneutical inquiry PhD Dissertation, UCL Institute of
                  Education (2020).
                  <br />
                  <SafeLink
                    to="https://discovery.ucl.ac.uk/id/eprint/10094873/1/THESIS%20SUBMISSION%20M%20PLEN.pdf"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Potts, DW Young Black Men and Mathematics: Exploring Changes,
                  Young Black Men and Mathematics: Exploring Changes,
                  Influences, and Perceptions of Social Justice Pedagogy PhD
                  Dissertation, Georgia State University.
                  <br />
                  <SafeLink
                    to="https://scholarworks.gsu.edu/cgi/viewcontent.cgi?article=1108&context=mse_diss"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Roffee, JA & Burns K “International student mobility & study
                  tours as a tool for social justice” (2020) Teaching in Higher
                  Education: Critical Perspectives.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1080/13562517.2020.1765328"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Salvador K, Paetz AM & Tippetts MM “We All Have a Little More
                  Homework to Do:”: A Constructivist Grounded Theory of
                  Transformative Learning Processes for Practicing Music
                  Teachers Encountering Social Justice” (2020) Journal of
                  Research in Music Education.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1177/0022429420920630"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Sieloff M Supporting the Needs of English Language Learners
                  through Social Justice Leadership: A Mixed Methods Approach
                  Doctor of Education Dissertation, The George Washington
                  University.
                  <br />
                  <SafeLink
                    to="https://search.proquest.com/docview/2393672395?pq-origsite=gscholar"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Soutter M “Measuring joy: A social justice issue” (2020) 101
                  Phi Delta Kappan 25–30.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1177/0031721720923517"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Suhendra, Nurlaelah E & Itoh E “Social Justice in and through
                  Mathematics Education: For Improving the Quality of
                  Mathematics Teaching and Internalising Students’ Character
                  Building” (2020) 68 Science Reports of the Faculty of
                  Education, Gunma University 1–6.
                  <br />
                  <SafeLink
                    to="https://gair.media.gunma-u.ac.jp/dspace/bitstream/10087/13057/1/01%20GKSIK-ITOH.pdf"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Tang H & Bao Y “Social Justice and K-12 Teachers’ Effective
                  Use of OER: A Cross-Cultural Comparison by Nations” (2020) 1
                  Journal of Interactive Media in Education 9.
                  <br />
                  <SafeLink
                    to="http://doi.org/10.5334/jime.576"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Tikly L & AM Barrett “Social justice, capabilities and the
                  quality of education in low income countries” (2011) 31
                  International Journal of Educational Development 3–14.
                  <br />
                  <SafeLink
                    to="https://www.sciencedirect.com/science/article/pii/S0738059310000726"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Treichler EBH & Crawford JN “Diversity and social justice
                  training at the postdoctoral level: A scoping study and pilot
                  of a self-assessment” (2020) 14 Training and Education in
                  Professional Psychology 126–137.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1037/tep0000281"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Vaccaro A, Olerio J, Olerio J, Knibb D, Forsythe K &
                  Capobianco CB “Fostering Allyship in Ourselves and Our
                  Students: Findings From a Duoethnography on Social Justice in
                  Higher Education” in Keengwe J (ed) Handbook of Research on
                  Diversity and Social Justice in Higher Education (2020) 41–54,
                  United States: IGI Global.
                  <br />
                  <SafeLink
                    to="https://www.igi-global.com/chapter/fostering-allyship-in-ourselves-and-our-students/253791"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Health, Mental Health and Nutrition</h2>
            <div>
              <ol>
                <li>
                  Bird MY & Austin JT “Microaggressions, Stereotypes, and Social
                  Stigmatization in the Lived Experiences of Socially
                  Marginalized Patients/Clients: A Social Justice Perspective”
                  (2020) Prejudice, Stigma, Privilege, and Oppression 201–214.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1007/978-3-030-35517-3_12"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Hassim S “Social justice, care and developmental welfare in
                  South Africa: a capabilities perspective” (2008) 34 Social
                  Dynamics: A journal of African studies 104–118.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/02533950802278448"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  McKay F & Taket A Health Equity, Social Justice and Human
                  Rights 2 ed (2020), United Kingdom: Routledge.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books?hl=en&lr=&id=Cu_eDwAAQBAJ&oi=fnd&pg=PT7&ots=eHT3cM3Kd2&sig=2AsZooAZo-jy58S4fZ6_A2N9U0Q#v=onepage&q&f=false"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Tol WA “Interpersonal violence and mental health: a social
                  justice framework to advance research and practice” (2020)
                  Global Mental Health 1–8.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1017/gmh.2020.4"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Land, Other Property and Agriculture</h2>
            <div>
              <ol>
                <li>
                  Apaydin V “Heritage, memory and social justice: reclaiming
                  space and identity” inApaydin V (ed) Critical Perspectives on
                  Cultural Memory and Heritage: Construction, Transformation and
                  Destruction (2020), London: UCL Press.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books?hl=en&lr=lang_en&id=7C7eDwAAQBAJ&oi=fnd&pg=PA84&dq=social+justice&ots=PPI_T5fWjz&sig=Z9RURCT3DVerwhaea0pHGICmKP4#v=onepage&q=social%20justice&f=false"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Leakly RRB “A re-boot of tropical agriculture benefits food
                  production, rural economies, health, social justice and the
                  environment” (2020) Nature Food 260–265.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1038/s43016-020-0076-z "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Van der Walt AJ “Property, social justice and citizenship:
                  Property law in Post-apartheid South Africa” (2008) 19
                  Stellenbosch Law Review 325–346.
                  <br />
                  <SafeLink
                    to="https://scholar.sun.ac.za/handle/10019.1/104008"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Wardani WI “Ownership Limitation to the Land Rights Based on
                  the Social Justice in Accordance to the Renewal of National
                  Agrarian Rights Act” (2020) 140 Advances in Economics,
                  Business and Management Research.
                  <br />
                  <SafeLink
                    to="http://scholar.google.co.za/scholar_url?url=https://download.atlantis-press.com/article/125940536.pdf&hl=en&sa=X&d=17617161493935059116&scisig=AAGBfm3G0W1DoxFaVnivof9N0wxTZSqHFA&nossl=1&oi=scholaralrt&hist=JKq3FLMAAAAJ:3557360648125673665:AAGBfm0WRue5i9ykvJeL_6J7npQwafKFgA"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Economy/Economic Inclusion and Well-being</h2>
            <div>
              <ol>
                <li>
                  Alotaibi KO & Hariri MM “Content Analysis of Shariah-Compliant
                  Investment Equity Funds in KSA: Does Social Justice Matter?”
                  (2020) 15 International Journal of Business and Management.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.5539/ijbm.v15n6p1 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Capps G “A bourgeois reform with social justice? The
                  contradictions of the Minerals Development Bill and black
                  economic empowerment in the South African platinum mining
                  industry” (2012) 39 Review of African Political Economy
                  315–333.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/03056244.2012.688801"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Fitzroy F & Nolan M “Towards Economic Democracy and Social
                  Justice: Profit Sharing, Co-Determination, and Employee
                  Ownership” (2020) Discussion Paper Series, Institute of Labor
                  Economics (IZA).
                  <br />
                  <SafeLink
                    to="http://scholar.google.co.za/scholar_url?url=http://ftp.iza.org/dp13238.pdf&hl=en&sa=X&d=10091363834372190126&scisig=AAGBfm1N3n9aPsOA9ch1Ox84iGRf0g0NeQ&nossl=1&oi=scholaralrt&hist=JKq3FLMAAAAJ:3557360648125673665:AAGBfm0WRue5i9ykvJeL_6J7npQwafKFgA"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Fitzroy F & Jin J “Reforming Tax and Welfare: Social Justice
                  and Recovery after the Pandemic” (2020) IZA Policy Papers 157,
                  Institute of Labour Economics (IZA).
                  <br />
                  <SafeLink
                    to="https://ideas.repec.org/p/iza/izapps/pp157.html"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Newell P & JG Frynas “Beyond CSR? Business, poverty and social
                  justice: an introduction” (2007) 28 Third World Quarterly
                  669–681.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/01436590701336507"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Unterhalter E “Economic Rationality or Social Justice? Gender,
                  the National Qualifications Framework and educational reform
                  in South Africa, 1989‐1996” (1998) 28 Cambridge Journal of
                  Education 351–368.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/0305764980280307"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Yigen K “Enforcing social justice: Economic and social rights
                  in South Africa” (2000) 4 The International Journal of Human
                  Rights 13–29.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/13642980008406873"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Peace and Social Cohesion</h2>
            <div>
              <ol>
                <li>
                  Dale C & D Kalob “Embracing Social Activism: Sociology in the
                  Service of Social Justice and Peace” (2006) 30 Humanity &
                  Society 121–152.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/13642980008406873"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Ndura E "Calling Institutions of Higher Education to Join the
                  Quest for Social Justice and Peace" (2007) 77 Harvard
                  Educational Review 345–350.
                  <br />
                  <SafeLink
                    to="https://hepgjournals.org/doi/abs/10.17763/haer.77.3.7265783p27800280"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Novelli M & Y Sayed “Teachers as agents of sustainable peace,
                  social cohesion and development: theory, practice & evidence”
                  (2016) 20 Education as Change 15–37.
                  <br />
                  <SafeLink
                    to="http://www.scielo.org.za/scielo.php?script=sci_arttext&pid=S1947-94172016000300002"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Climate Change and Environmental Sustainability</h2>
            <div>
              <ol>
                <li>
                  De Santo EM “Missing marine protected area (MPA) targets: How
                  the push for quantity over quality undermines sustainability
                  and social justice” (2013) 124 Journal of Environmental
                  Management 137–146.
                  <br />
                  <SafeLink
                    to="https://www.sciencedirect.com/science/article/pii/S0301479713000753"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Scott D & C Oelofse “Social and Environmental Justice in South
                  African Cities: Including ‘Invisible Stakeholders’ in
                  Environmental Assessment Procedures” (2005) 48 Journal of
                  Environmental Planning and Management 445–467.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/09640560500067582"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Swaffield L & Egan D The Welsh Doughnut: A framework for
                  environmental sustainability and social justice (2020) An
                  Oxfam Research Report.
                  <br />
                  <SafeLink
                    to="https://oxfamilibrary.openrepository.com/bitstream/handle/10546/620979/rr-welsh-doughnut-2020-sustainability-social-justice-010320-en.pdf?sequence=1 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Politics and Democracy</h2>
            <div>
              <ol>
                <li>
                  Brock-Utne B “The Language Question in Africa in the Light of
                  Globalisation, Social Justice and Democracy” (2003) 8
                  International Journal of Peace Studies 67–87.
                  <br />
                  <SafeLink
                    to="https://www.jstor.org/stable/41852902?seq=1"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Dowding K, RE Goodin & C Pateman (eds) Justice and Democracy:
                  Essays for Brian Barry (2004), Cambridge: Cambridge University
                  Press.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books/about/Justice_and_Democracy.html?id=DqSH3dNHa7MC&printsec=frontcover&source=kp_read_button&redir_esc=y#v=onepage&q&f=false"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Fainstein SS “Competitiveness, Cohesion, and Governance: Their
                  Implications for Social Justice” (2001) 25 International
                  Journal of Urban and Regional Research 884–888.
                  <br />
                  <SafeLink
                    to="https://www.ijurr.org/article/competitiveness-cohesion-and-governance-their-implications-for-social-justice/"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Merkel W & M Krück “Social Justice and Democracy:
                  Investigating the Link” (2004) 1 International Politics and
                  Society 134–158.
                  <br />
                  <SafeLink
                    to="https://www.fes.de/ipg/arc_04_set/set_01_04e.htm"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Nagda BA, P Gurin & GE Lopez “Transformative Pedagogy for
                  Democracy and Social Justice” (2003) 6 Race, Ethnicity and
                  Education 165–191.
                  <br />
                  <SafeLink
                    to="https://igr.umich.edu/files/igr/Gurin-TransformativePedago.pdf"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Toens K “The Dilemma of Regress: Social Justice and Democracy
                  in Recent Critical Theory” (2007) 6 European Journal of
                  Political Theory 160–179.
                  <br />
                  <SafeLink
                    to="https://journals.sagepub.com/doi/10.1177/1474885107074348?icid=int.sj-abstract.similar-articles.1"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Gender and Other Intersectional Exclusions</h2>
            <div>
              <ol>
                <li>
                  Beyrer C “LGBT Africa: A social justice movement emerges in
                  the era of HIV” (2012) 9 SAHARA-J: Journal of Social Aspects
                  of HIV/AIDS 177–179.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/full/10.1080/17290376.2012.743813"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Buchanan NT & Wiklund LO “Clinical Science Must Change or Die:
                  Integrating Intersectionality and Social Justice” (2020) Women
                  & Therapy Journal.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1080/02703149.2020.1729470"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Brinkman BG & Donohue P “Doing intersectionality in social
                  justice oriented clinical training” (2020) 14 Training and
                  Education in Professional Psychology 109–115.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1037/tep0000274"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Callahan JC & DE Roberts “A Feminist Social Justice Approach
                  to Reproduction-Assisting Technologies: A Case Study on the
                  Limits of Liberal Theory” (1996) 84 Kentucky Law Journal
                  1197–1234.
                  <br />
                  <SafeLink
                    to="https://uknowledge.uky.edu/klj/vol84/iss4/15/?utm_source=uknowledge.uky.edu%2Fklj%2Fvol84%2Fiss4%2F15&utm_medium=PDF&utm_campaign=PDFCoverPages"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Chamallas M “Social Justice Feminism: A New Take on
                  Intersectionality” (2014) 1 Freedom Center Journal 11–20.
                  <br />
                  <SafeLink
                    to="https://heinonline.org/HOL/PDFsearchable?collection=journals&handle=hein.journals/freecejo5&div=6&section=6&print=section&from=dropbox"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Cross J “Imprisoning Pregnant and Parenting Women: A Focus on
                  Social Justice, Equal Rights, and Equality” (2020) 24 Health &
                  Social Work.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1093/hsw/hlaa008 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Devereux S & C Solomon Can Social Protection Deliver Social
                  Justice for Farmwomen in South Africa? (2011) unpublished
                  paper submitted for the conference Social Protection for
                  Social Justice hosted by the Institute of Development Studies,
                  Brighton, UK,13 to 15-04-2011.
                  <br />
                  <SafeLink
                    to="http://www.ids.ac.uk/files/dmfile/DevereuxSolomon2011FarmwomenandsocialprotectioninSouthAfricaCSPconferencedraft.pdf"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Fraser N “Social Justice in the Age of Identity Politics:
                  Redistribution, Recognition, and Participation” in L Ray & A
                  Sayer (eds) Culture and Economy after the Cultural Turn (1999)
                  25–52, London: SAGE Publications Ltd.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books?id=2oiOCk8E2lAC&lpg=PP1&pg=PA25#v=onepage&q&f=false"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Chapter available here &rarr;
                  </SafeLink>
                  <SafeLink
                    to="https://sk.sagepub.com/books/culture-and-economy-after-the-cultural-turn"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Book available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Fraser N “Feminist Politics in the Age of Recognition: A
                  Two-Dimensional Approach to Gender Justice” (2007) 1 Studies
                  in Social Justice 23–35.
                  <br />
                  <SafeLink
                    to="https://journals.library.brocku.ca/index.php/SSJ/article/view/979"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Gray M, K Agllias & K Davies “Social justice Feminism” in M
                  Reisch (ed) The Routledge International Handbook of Social
                  Justice (2014) 173–187, London: Routledge.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books?id=STVEAwAAQBAJ&lpg=PP1&dq=Routledge%20International%20Handbook%20of%20Social%20Justice%20social%20justice%20feminism&pg=PA173#v=onepage&q=Routledge%20International%20Handbook%20of%20Social%20Justice%20social%20justice%20feminism&f=false"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Chapter available here &rarr;
                  </SafeLink>
                  <SafeLink
                    to="https://www.routledge.com/Routledge-International-Handbook-of-Social-Justice-1st-Edition/Reisch/p/book/9780415620437"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Book available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Kalsem K & VL Williams "Social Justice Feminism" (2010) 18
                  UCLA Women’s Law Journal 131–193.
                  <br />
                  <SafeLink
                    to="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=1112105"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Lasker GA & Simcox NJ “Using Feminist Theory and Social
                  Justice Pedagogy to Educate a New Generation of Precautionary
                  Principle Chemists” (2020) 6 Catalyst: Feminism, Theory,
                  Technoscience.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.28968/cftt.v6i1.32084"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Mathieson A, E Branam & A Noble “Prostitution Policy:
                  Legalization, Decriminalization and the Nordic Model” (2016)
                  14 Seattle Journal for Social Justice 367–428.
                  <br />
                  <SafeLink
                    to="https://digitalcommons.law.seattleu.edu/sjsj/vol14/iss2/10/"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Muncy R “To Live Here You Have to Fight: How Women Led
                  Appalachian Movements for Social Justice by Jessica Wikerson”
                  (2020) 17 Labor 134–136.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1215/15476715-8114950"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Nussbaum MC “Capabilities as Fundamental Entitlements: Sen and
                  Social Justice” (2003) 9 Feminist Economics 33–59.
                  <br />
                  <SafeLink
                    to="https://philpapers.org/archive/NUSCAF.pdf"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Saville KM “Co-constructed Auto/Biographies in Dwarfism
                  Mothering Research: Imagining Opportunities for Social
                  Justice” in Parsons JM & Chappell A (eds) The Palgrave
                  Handbook of Auto/Biography (2020) 633–656, UK: Palgrave
                  Macmillan.
                  <br />
                  <SafeLink
                    to="https://link.springer.com/chapter/10.1007/978-3-030-31974-8_27"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Unterhalter E “Economic Rationality or Social Justice? Gender,
                  the National Qualifications Framework and educational reform
                  in South Africa, 1989–1996” (1998) 28 Cambridge Journal of
                  Education 351–368.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/0305764980280307"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Vale C, Averill R, Hall J, Forgasz H & Leder G “Equity, Social
                  Justice, and Ethics” (2016–2019) Research in Mathematics
                  Education in Australasia 177–208.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1007/978-981-15-4269-5_8"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>Diverse</h2>
            <div>
              <ol>
                <li>
                  Brister E “Field Philosophy and Social Justice” (2020) Social
                  Epistemology: A Journal of Knowledge, Culture and Policy.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1080/02691728.2020.1757176 "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Jones TS & A Bodtker “A dialectical analysis of a social
                  justice process: International collaboration in South Africa”
                  (1998) 26 Journal of Applied Communication Research 357–373.
                  <br />
                  <SafeLink
                    to="https://www.tandfonline.com/doi/abs/10.1080/00909889809365514"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Loganathan S “Social Justice and Its Varied Dimensions” (2020)
                  31 Purakala.
                  <br />
                  <SafeLink
                    to="https://www.purakala.com/index.php/0971-2143/article/view/621"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Shriberg D, Brooks K & Montes de Oca J “Child Rights, Social
                  Justice, and Professional Ethics” (2020) International
                  Handbook on Child Rights and School Psychology 37–48.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1007/978-3-030-37119-7_3"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Sewpaul V “A structural social justice approach to family
                  policy: a critique of the draft South African family policy”
                  (2005) 41 Social Work 310–323.
                  <br />
                  <SafeLink
                    to="https://socialwork.journals.ac.za/pub/article/view/312"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Surrels K “Social justice, diversity, and intercultural –
                  global citizenship education in the global context” in The
                  Routledge Handbook of Language and Intercultural Communication
                  2 ed (2020), Abingdon: Routledge.
                  <br />
                  <SafeLink
                    to="https://books.google.co.za/books?hl=en&lr=&id=3XvgDwAAQBAJ&oi=fnd&pg=PT484&ots=ZDaEpF3Xon&sig=UM1DKBAIEEPDtjvgPD5cgcHrE0o#v=onepage&q&f=false "
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  Washington K “Journey to Authenticity: Afrikan Psychology as
                  an Act of Social Justice Honoring Afrikan Humanity” (2020)
                  Journal of Humanistic Psychology.
                  <br />
                  <SafeLink
                    to="https://doi.org/10.1177/0022167820917232"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
          <CollapsibleBox collapsibleByDefault>
            <h2>World Reports</h2>
            <div>
              <ol>
                <li>
                  Sulla V & P Zikhali Overcoming Poverty and Inequality in South
                  Africa: An Assessment of Drivers, Constraints and
                  Opportunities (English) (2018), Washington, DC: World Bank
                  Group.
                  <br />
                  <SafeLink
                    to="http://documents.worldbank.org/curated/en/530481521735906534/Overcoming-Poverty-and-Inequality-in-South-Africa-An-Assessment-of-Drivers-Constraints-and-Opportunities"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  UN Universal Declaration of Human Rights (1948).
                  <br />
                  <SafeLink
                    to="https://www.un.org/en/universal-declaration-human-rights/"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  UN Universal Declaration of Human Rights illustrated edition
                  (2015).
                  <br />
                  <SafeLink
                    to="https://www.un.org/en/udhrbook/pdf/udhr_booklet_en_web.pdf"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  UNDP Human Development Report 2019 – Beyond income, beyond
                  averages, beyond today: Inequalities in human development in
                  the 21st century (2019), New York: UN.
                  <br />
                  <SafeLink
                    to="http://hdr.undp.org/en/2019-report"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
                <li>
                  World Bank South Africa – Systematic country diagnostic: an
                  incomplete transition – overcoming the legacy of exclusion in
                  South Africa (English) (2018), Washington, DC: World Bank
                  Group.
                  <br />
                  <SafeLink
                    to="http://documents.worldbank.org/curated/en/815401525706928690/South-Africa-Systematic-country-diagnostic-an-incomplete-transition-overcoming-the-legacy-of-exclusion-in-South-Africa"
                    css={tw`border-b-2 border-maroon hover:text-maroon`}
                  >
                    Available here &rarr;
                  </SafeLink>
                </li>
              </ol>
            </div>
          </CollapsibleBox>
        </div>
      </Section>
    </Layout>
  );
};

export default DataResearch;

export const pageQuery = graphql`
  query {
    homeHero: file(relativePath: { eq: "home-hero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
